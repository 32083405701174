import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=595c0110&scoped=true"
import script from "./Navbar.vue?vue&type=script&lang=js"
export * from "./Navbar.vue?vue&type=script&lang=js"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=595c0110&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "595c0110",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnLogin: require('/Users/macssd/WorkFolder/corrent_project/online_kassa/nuxtjsagr_frontend/components/BtnLogin.vue').default})
