import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _221d4a42 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _cc3ede50 = () => interopDefault(import('../pages/carriers.vue' /* webpackChunkName: "pages/carriers" */))
const _13f22f94 = () => interopDefault(import('../pages/comments/index.vue' /* webpackChunkName: "pages/comments/index" */))
const _3a857849 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _01b7558b = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _62f458be = () => interopDefault(import('../pages/politics.vue' /* webpackChunkName: "pages/politics" */))
const _14e662ea = () => interopDefault(import('../pages/rules.vue' /* webpackChunkName: "pages/rules" */))
const _68f12dba = () => interopDefault(import('../pages/order/error.vue' /* webpackChunkName: "pages/order/error" */))
const _29ed47b5 = () => interopDefault(import('../pages/order/success.vue' /* webpackChunkName: "pages/order/success" */))
const _22a9a885 = () => interopDefault(import('../pages/partners/route/index.vue' /* webpackChunkName: "pages/partners/route/index" */))
const _606add45 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5e2bfe4e = () => interopDefault(import('../pages/account/_id/index.vue' /* webpackChunkName: "pages/account/_id/index" */))
const _34c615ac = () => interopDefault(import('../pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _6804c712 = () => interopDefault(import('../pages/bus-search/_city_a/index.vue' /* webpackChunkName: "pages/bus-search/_city_a/index" */))
const _13af06fc = () => interopDefault(import('../pages/comments/_id.vue' /* webpackChunkName: "pages/comments/_id" */))
const _20abeb17 = () => interopDefault(import('../pages/page/_id.vue' /* webpackChunkName: "pages/page/_id" */))
const _9180418c = () => interopDefault(import('../pages/account/_id/active.vue' /* webpackChunkName: "pages/account/_id/active" */))
const _f1fcbfe0 = () => interopDefault(import('../pages/account/_id/history.vue' /* webpackChunkName: "pages/account/_id/history" */))
const _32dc6330 = () => interopDefault(import('../pages/bus-search/_city_a/_city_b/index.vue' /* webpackChunkName: "pages/bus-search/_city_a/_city_b/index" */))
const _6a058da2 = () => interopDefault(import('../pages/bus-search/_city_a/_city_b/order/index.vue' /* webpackChunkName: "pages/bus-search/_city_a/_city_b/order/index" */))
const _5722478c = () => interopDefault(import('../pages/bus-search/_city_a/_city_b/search.vue' /* webpackChunkName: "pages/bus-search/_city_a/_city_b/search" */))
const _acd48952 = () => interopDefault(import('../pages/bus-search/_city_a/_city_b/order/_id.vue' /* webpackChunkName: "pages/bus-search/_city_a/_city_b/order/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _221d4a42,
    name: "blog"
  }, {
    path: "/carriers",
    component: _cc3ede50,
    name: "carriers"
  }, {
    path: "/comments",
    component: _13f22f94,
    name: "comments"
  }, {
    path: "/faq",
    component: _3a857849,
    name: "faq"
  }, {
    path: "/partners",
    component: _01b7558b,
    name: "partners"
  }, {
    path: "/politics",
    component: _62f458be,
    name: "politics"
  }, {
    path: "/rules",
    component: _14e662ea,
    name: "rules"
  }, {
    path: "/order/error",
    component: _68f12dba,
    name: "order-error"
  }, {
    path: "/order/success",
    component: _29ed47b5,
    name: "order-success"
  }, {
    path: "/partners/route",
    component: _22a9a885,
    name: "partners-route"
  }, {
    path: "/",
    component: _606add45,
    name: "index"
  }, {
    path: "/account/:id",
    component: _5e2bfe4e,
    name: "account-id"
  }, {
    path: "/blog/:id",
    component: _34c615ac,
    name: "blog-id"
  }, {
    path: "/bus-search/:city_a",
    component: _6804c712,
    name: "bus-search-city_a"
  }, {
    path: "/comments/:id",
    component: _13af06fc,
    name: "comments-id"
  }, {
    path: "/page/:id?",
    component: _20abeb17,
    name: "page-id"
  }, {
    path: "/account/:id?/active",
    component: _9180418c,
    name: "account-id-active"
  }, {
    path: "/account/:id?/history",
    component: _f1fcbfe0,
    name: "account-id-history"
  }, {
    path: "/bus-search/:city_a?/:city_b",
    component: _32dc6330,
    name: "bus-search-city_a-city_b"
  }, {
    path: "/bus-search/:city_a?/:city_b?/order",
    component: _6a058da2,
    name: "bus-search-city_a-city_b-order"
  }, {
    path: "/bus-search/:city_a?/:city_b?/search",
    component: _5722478c,
    name: "bus-search-city_a-city_b-search"
  }, {
    path: "/bus-search/:city_a?/:city_b?/order/:id",
    component: _acd48952,
    name: "bus-search-city_a-city_b-order-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
